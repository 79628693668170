import React, { useState, useEffect } from "react";
import { Column, Row, Button, Img, Text, Line, List, Stack } from "components";
import Sidebar from "components/Sidebar";
import ListDepo from "components/ListDepo";
import ListDepo1 from "components/ListDepo1";
import Dropdown from "components/Dropdown";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';
import UserService from "../../services/user.service";
import { EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';

const DepositsPage = () => {


  let calssSoled = true;
  const { isLoggedIn } = useSelector(state => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [balance, setBalance] = useState("");

  // const response = JSON.parse(localStorage.getItem('user'));
  // const username = response.response.user.username
  // const state  = response.response

  console.log("user :", currentUser);

  // const userId = currentUser.response.user.id


  const [showBalanceIcon, setShowBalanceIcon] = useState(false);

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }

  const classNameSolde = "text-amber_A700 font-montserrat font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]";
  const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";
  if (currentUser == null) {
    <Navigate to="/dashboardsuperviseur" />;

  }
  useEffect(() => {
    if (currentUser) {
      UserService.getBalance(currentUser.response.user.id).then(
        (response) => {
          setBalance(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setBalance(_content);
        }
      );
    }
  }, []);

  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false
  }

  const navigate = useNavigate();

  function handleNavigate4() {
    navigate("/deposits");
  }
  function handleNavigate4() {
    navigate("/dashboardsuperviseur");
  }
  function handleNavigate5() {
    navigate("/dashboardsuperviseur");
  }

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {


    return (
      <>
        <Column className="bg-bluegray_50  items-center justify-start mx-[auto] w-[100%]">
          <Row className=" justify-between w-[100%]">
            <Sidebar className="w-[18%]" onDashboardClick={handleNavigate4} />
            <Column className="bg-white_A700 items-center lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
              <Column className="items-center justify-start lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] w-[100%]">
                <Row className="items-start w-[92%]">
                  <Button
                    className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    size="smIcn"
                    variant="icbFillGray201"
                  >
                    <Img
                      src="images/img_arrowleft.svg"
                      className="flex items-center justify-center"
                      onClick={handleNavigate5}
                      alt="arrowleft"
                    />
                  </Button>
                  <Text className="Modifieruncai" as="h5" variant="h5">
                    Appel de fond
                  </Text>

                  {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[70%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}
               <span  className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px]"><EyeOutlined onClick={handleBalanceIconClick}   /></span> 
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[70%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
               <span className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "><EyeInvisibleOutlined onClick={handleBalanceIconClick} /></span>
            </Row>
             )}

                  {/* <Text
                    className="font-bold font-inter mb-[1px] lg:ml-[153px] xl:ml-[191px] 2xl:ml-[215px] 3xl:ml-[258px] text-black_900 w-[auto]"
                    as="h4"
                    variant="h4"
                  >
                    <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                      Solde{" "}
                    </span>
                    <span className={calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"} >
                      {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                    </span>
                  </Text>
                  <Img
                    src="images/img_ueyeslash.svg"
                    className="ueyeslash"
                    alt="ueyeslash"
                  /> */}
                </Row>

                <Line className="bg-black_900_19 h-[1px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]" />
                <Column className="justify-start lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[53px] w-[93%]">

                  {/* <Row className="items-end lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]">
                  <Text
                      className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]" 
                       as="h4"
                       variant="h4"
                       
                  >
                    Historique des appel de fond du superviseur
                  </Text>
                  
                </Row>
             
             
                <br />
                
                 
                <Row className="bg-white_A700 items-center justify-between lg:p-[20px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                <ListDepo state={currentUser ? (currentUser.response):null}/>
                </Row>

                <br /> */}

                  <Row className="items-end lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]">
                    <Text
                      className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]"
                      as="h4"
                      variant="h4"

                    >
                      Historique des appel de fond de ces caissiers
                    </Text>

                  </Row>

                  <br />

                  <Row className="bg-white_A700 items-center justify-between lg:p-[20px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                    <ListDepo1 state={currentUser ? (currentUser.response) : null} />
                  </Row>

                </Column>
              </Column>
            </Column>
            <Column className="items-center w-[15%]">
              <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">

                <Dropdown color="white" />
                <Text
                  className="text-bluegray_600 font-semibold text-black_900 w-[auto]"
                  variant="body1"
                >
                  <strong>{currentUser ? (currentUser.response.user.username) : null}</strong>
                </Text>
                <Img
                  src="images/img_ellipse1.png"
                  className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                  alt="EllipseOne"
                />
              </Row>
              <List
                className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                orientation="vertical"
              >
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorTwo"
                        alt="VectorTwo"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorOne"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorThree"
                        alt="VectorThree"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorFour"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
              </List>
              <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                <Text className="columnfluxdetransac_two" variant="body2">
                  Flux de transactions
                </Text>
                <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                  <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                    <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                      <Row className="items-start justify-between w-[100%]">
                        <Text className="Thirty" variant="body2">
                          30%
                        </Text>
                        <Text className="Forty" variant="body2">
                          40%
                        </Text>
                      </Row>
                      <Text className="columnthirty" variant="body2">
                        30%
                      </Text>
                    </Column>
                  </Stack>
                </Column>
              </Column>
              <Button
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                  />
                }
                shape="RoundedBorder8"
                size="lg"
              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Réapprovisionner
                </div>
              </Button>
            </Column>
          </Row>
        </Column>
      </>
    );
  }
};

export default DepositsPage;
