import React from "react";

import { Column, Row, Button, Img, Text, Line, List, Stack } from "components";
import Sidebar from "components/Sidebar";
import { useNavigate } from "react-router-dom";
import ListInfo from "components/ListInfo";

const InfosCaissierPage = () => {
  const navigate = useNavigate();

  function handleNavigate3() {
    navigate("/modifycaissier");
  }
  function handleNavigate4() {
    navigate("/dashboardsuperviseur");
  }
  function handleNavigate5() {
    navigate("/caissiers");
  }

  return (
    <>
      <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <Row className=" justify-between w-[98%]">
          <Sidebar className="w-[18%]" onDashboardClick={handleNavigate4} />
          <Column className="bg-white_A700 items-center lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
            <Column className="items-center justify-start lg:mt-[20px] xl:mt-[25px] 2xl:mt-[28px] 3xl:mt-[34px] w-[100%]">
              <Row className="items-start w-[92%]">
                <Button
                  className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                  size="smIcn"
                  variant="icbFillGray201"
                >
                  <Img
                    src="images/img_arrowleft.svg"
                    className="flex items-center justify-center"
                    onClick={handleNavigate5}
                    alt="arrowleft"
                  />
                </Button>
                <Text className="Modifieruncai" as="h5" variant="h5">
                  Information caisse
                </Text>
                <Text
                  className="font-bold font-inter mb-[1px] lg:ml-[153px] xl:ml-[191px] 2xl:ml-[215px] 3xl:ml-[258px] text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                    Solde{" "}
                  </span>
                  <span className="text-bluegray_600 font-montserrat lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                    1.000.000 Fcfa
                  </span>
                </Text>
                <Img
                  src="images/img_ueyeslash.svg"
                  className="ueyeslash"
                  alt="ueyeslash"
                />
              </Row>
              <Line className="bg-black_900_19 h-[1px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]" />
              <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[93%]">
                <Row className="items-start w-[99%]">
                  <Img
                    src="images/img_camera_44X44.svg"
                    className="uexchange_One"
                    alt="camera"
                  />
                  <Text
                    className="mb-[4px] lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] text-black_900_cc w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Modou Fall
                  </Text>
                  <Img
                    src="images/img_trash.svg"
                    className="lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] lg:ml-[371px] xl:ml-[464px] 2xl:ml-[522px] 3xl:ml-[626px] lg:my-[4px] 2xl:my-[5px] xl:my-[5px] 3xl:my-[6px] lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    alt="trash"
                  />
                  <Img
                    src="images/img_link.svg"
                    className="common-pointer lg:h-[19px] xl:h-[24px] 2xl:h-[27px] 3xl:h-[33px] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] lg:my-[5px] xl:my-[6px] 2xl:my-[7px] 3xl:my-[9px] lg:w-[18px] xl:w-[23px] 2xl:w-[26px] 3xl:w-[32px]"
                    onClick={handleNavigate3}
                    alt="link"
                  />
                  <Button
                    className="flex items-center justify-center lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] uexchange_One"
                    size="smIcn"
                    variant="icbFillIndigoA700"
                  >
                    <Img
                      src="images/img_eye.svg"
                      className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                      alt="eye"
                    />
                  </Button>
                </Row>
                <Row className="items-end lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]">
                  <Text
                    className="font-semibold lg:mb-[4px] xl:mb-[5px] 2xl:mb-[6px] 3xl:mb-[8px] text-black_900_cc w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    Historique des transactions
                  </Text>

                </Row>

                {/* <List
                  className="gap-[0] min-h-[auto] lg:mt-[16px] xl:mt-[21px] 2xl:mt-[23px] 3xl:mt-[28px] w-[100%]"
                  orientation="vertical"
                >
                  <Row className="listticket">
                    <Button
                      className="flex items-center justify-center uexchange_One"
                      size="smIcn"
                      variant="icbFillWhiteA701"
                    >
                      <Img
                        src="images/img_group190.png"
                        className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                        alt="Group190"
                      />
                    </Button>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Facturier
                    </Text>
                    <Text className="Maristes" variant="body1">
                      Maristes
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Il y a 2mn
                    </Text>
                    <Text className="fcfaCounter" variant="body1">
                      15.000 fcfa
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="user9"
                      alt="user"
                    />
                  </Row>
                  <Row className="listticket">
                    <Button
                      className="flex items-center justify-center mt-[1px] uexchange_One"
                      size="smIcn"
                      variant="icbFillCyan500"
                    >
                      <Img
                        src="images/img_group191.png"
                        className="flex items-center justify-center"
                        alt="Group191"
                      />
                    </Button>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Mobile Money
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Point-E
                    </Text>
                    <Text className="Ilya4mn" variant="body1">
                      Il y a 4mn
                    </Text>
                    <Text className="language_Three" variant="body1">
                      56.000 fcfa{" "}
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="mb-[1px] lg:ml-[18px] xl:ml-[22px] 2xl:ml-[25px] 3xl:ml-[30px] uexchange_One"
                      alt="user One"
                    />
                  </Row>
                  <Row className="listticket">
                    <Stack className="lg:h-[32px] xl:h-[39px] 2xl:h-[44px] 3xl:h-[53px] w-[5%]">
                      <div className="absolute bg-gray_501 inset-[0] justify-center m-[auto] rounded-radius8 uexchange_One"></div>
                      <Img
                        src="images/img_unnamed13.png"
                        className="absolute lg:h-[32px] xl:h-[39px] 2xl:h-[44px] 3xl:h-[53px] rounded-radius8 w-[100%]"
                        alt="unnamedThirteen"
                      />
                    </Stack>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Facturier
                    </Text>
                    <Text className="Maristes" variant="body1">
                      Maristes
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Il y a 2mn
                    </Text>
                    <Text className="fcfaCounter" variant="body1">
                      15.000 fcfa
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="user9"
                      alt="user Two"
                    />
                  </Row>
                  <Row className="listticket">
                    <Button
                      className="flex items-center justify-center uexchange_One"
                      size="smIcn"
                      variant="icbFillWhiteA701"
                    >
                      <Img
                        src="images/img_group192.png"
                        className="flex items-center justify-center"
                        alt="Group192"
                      />
                    </Button>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Mobile Money
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Point-E
                    </Text>
                    <Text className="Ilya4mn" variant="body1">
                      Il y a 4mn
                    </Text>
                    <Text className="language_Three" variant="body1">
                      56.000 fcfa{" "}
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="lg:ml-[18px] xl:ml-[22px] 2xl:ml-[25px] 3xl:ml-[30px] uexchange_One"
                      alt="user Three"
                    />
                  </Row>
                  <Row className="listticket">
                    <Stack className="lg:h-[31px] xl:h-[39px] 2xl:h-[43px] 3xl:h-[52px] mt-[1px] w-[5%]">
                      <div className="absolute bg-gray_501 bottom-[2%] rounded-radius8 uexchange_One"></div>
                      <Img
                        src="images/img_unnamed3.png"
                        className="absolute lg:h-[31px] xl:h-[39px] 2xl:h-[43px] 3xl:h-[52px] w-[100%]"
                        alt="unnamedThree"
                      />
                    </Stack>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Facturier
                    </Text>
                    <Text className="Maristes" variant="body1">
                      Maristes
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Il y a 2mn
                    </Text>
                    <Text className="fcfaCounter" variant="body1">
                      15.000 fcfa
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="user9"
                      alt="user Four"
                    />
                  </Row>
                  <Row className="listticket">
                    <Stack className="bg-gray_501 mt-[1px] rounded-radius8 uexchange_One">
                      <Img
                        src="images/img_27332168912906.png"
                        className="absolute rounded-radius8 uexchange_One"
                        alt="27332168912906"
                      />
                    </Stack>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Mobile Money
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Point-E
                    </Text>
                    <Text className="Ilya4mn" variant="body1">
                      Il y a 4mn
                    </Text>
                    <Text className="language_Three" variant="body1">
                      56.000 fcfa{" "}
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="mb-[1px] lg:ml-[18px] xl:ml-[22px] 2xl:ml-[25px] 3xl:ml-[30px] uexchange_One"
                      alt="user Five"
                    />
                  </Row>
                  <Row className="listticket">
                    <Button
                      className="flex items-center justify-center mt-[1px] uexchange_One"
                      size="smIcn"
                      variant="icbFillWhiteA701"
                    >
                      <Img
                        src="images/img_group190.png"
                        className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                        alt="Group194"
                      />
                    </Button>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Facturier
                    </Text>
                    <Text className="Maristes" variant="body1">
                      Maristes
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Il y a 2mn
                    </Text>
                    <Text className="fcfaCounter" variant="body1">
                      15.000 fcfa
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="mb-[1px] user9"
                      alt="user Six"
                    />
                  </Row>
                  <Row className="listticket">
                    <Button
                      className="flex items-center justify-center uexchange_One"
                      size="smIcn"
                      variant="icbFillCyan500"
                    >
                      <Img
                        src="images/img_group191.png"
                        className="flex items-center justify-center"
                        alt="Group196"
                      />
                    </Button>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Mobile Money
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Point-E
                    </Text>
                    <Text className="Ilya4mn" variant="body1">
                      Il y a 4mn
                    </Text>
                    <Text className="language_Three" variant="body1">
                      56.000 fcfa{" "}
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="lg:ml-[18px] xl:ml-[22px] 2xl:ml-[25px] 3xl:ml-[30px] uexchange_One"
                      alt="user Seven"
                    />
                  </Row>
                  <Row className="listticket">
                    <Stack className="lg:h-[32px] xl:h-[39px] 2xl:h-[44px] 3xl:h-[53px] w-[5%]">
                      <div className="absolute bg-gray_501 inset-[0] justify-center m-[auto] rounded-radius8 uexchange_One"></div>
                      <Img
                        src="images/img_unnamed13.png"
                        className="absolute lg:h-[32px] xl:h-[39px] 2xl:h-[44px] 3xl:h-[53px] rounded-radius8 w-[100%]"
                        alt="unnamedFourteen"
                      />
                    </Stack>
                    <Text className="CashPay1" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="Facturier" variant="body1">
                      Facturier
                    </Text>
                    <Text className="Maristes" variant="body1">
                      Maristes
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      Il y a 2mn
                    </Text>
                    <Text className="fcfaCounter" variant="body1">
                      15.000 fcfa
                    </Text>
                    <Img
                      src="images/img_user.svg"
                      className="user9"
                      alt="user Eight"
                    />
                  </Row>
                </List> */}

                <br />

                <Row className="bg-white_A700 items-center justify-between lg:p-[20px] xl:p-[13px] 2xl:p-[0px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                  <ListInfo />
                </Row>
              </Column>
            </Column>
          </Column>
          <Column className="items-center w-[15%]">
            <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
              <Button
                className="flex items-center justify-center uexchange_One"
                variant="icbFillGray200"
              >
                <Img
                  src="images/img_settings.svg"
                  className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                  alt="settings"
                />
              </Button>
              <Text
                className="font-semibold text-black_900 w-[auto]"
                variant="body1"
              >
                Boubou
              </Text>
              <Img
                src="images/img_ellipse1.png"
                className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                alt="EllipseOne"
              />
            </Row>
            <List
              className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
              orientation="vertical"
            >
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorTwo"
                      alt="VectorTwo"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorOne"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorThree"
                      alt="VectorThree"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorFour"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
            </List>
            <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
              <Text className="columnfluxdetransac_two" variant="body2">
                Flux de transactions
              </Text>
              <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                  <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                    <Row className="items-start justify-between w-[100%]">
                      <Text className="Thirty" variant="body2">
                        30%
                      </Text>
                      <Text className="Forty" variant="body2">
                        40%
                      </Text>
                    </Row>
                    <Text className="columnthirty" variant="body2">
                      30%
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Button
              className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
              leftIcon={
                <Img
                  src="images/img_camera_24X24.svg"
                  className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                  alt="camera"
                />
              }
              shape="RoundedBorder8"
              size="lg"
            >
              <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                Réapprovisionner
              </div>
            </Button>
          </Column>
        </Row>
      </Column>
    </>
  );
};

export default InfosCaissierPage;
