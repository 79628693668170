import axios from "axios";
import authHeader from "./auth-header";
const API_URL = `${process.env.REACT_APP_API_URL}/updateAppel`;
// const API_URL = "http://3.238.7.217:4041/api/v1/updateAppel";

//update solde
const updateappel = (id, montant, user, status, senderId, receiverId) => {
  const data = JSON.stringify({
    "id": id,
    "amount": montant,
    "comment": "Demande d'appel de fond ",
    "user": user,
    "status": status,
    "senderId": senderId,
    "receiverId": receiverId
  });
  const config = {
    method: 'put',
    url: API_URL,
    headers: authHeader(),
    data: data
  };
  return axios(config)
};

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  updateappel
}