import axios from "axios";
import authHeader from "./auth-header";
const API_URL = `${process.env.REACT_APP_API_URL}/updateCaissier`;
// const API_URL = "http://3.238.7.217:4041/api/v1/updateCaissier";

//update caissier

const updatecaissier = (id, firstName, lastName, username, phone, CNI, email, adresse, created_by) => {
  const data = JSON.stringify({
    "id": id,
    "firstName": firstName,
    "lastName": lastName,
    "username": username,
    "phone": phone,
    "CNI": CNI,
    "email": email,
    "adresse": adresse,
    "created_by": created_by
  });
  const config = {
    method: 'put',
    url: API_URL,
    headers: authHeader(),
    data: data
  };
  return axios(config)
};

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  updatecaissier
}