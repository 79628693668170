import React from "react";
import Infosuperviseur from "pages/Infosuperviseur";
import Ajoutsuperviseur from "pages/Ajoutsuperviseur";
import Listesuperviseursmaster from "pages/Listesuperviseursmaster";
import InfosCaissier from "pages/InfosCaissier";
import Caissiers from "pages/Caissiers";
import Transactions from "pages/Transactions";
import Deposits from "pages/Deposits";
import ModifyCaissier from "pages/ModifyCaissier";
import AjoutCaissier from "pages/AjoutCaissier";
import DashboardSuperviseur from "pages/DashboardSuperviseur";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Reclamations from "pages/Réclamations";
import Chat from "components/Chat";
import Login from "pages/Login";
import ProfilePage from "pages/Profile";
import ReapprovisionnementPage from "pages/Reapprovisionnement";
import AppelFonPage from "pages/AppelFond";


const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/dashboardsuperviseur" element={<Home />} /> */}
        <Route path="*" element={<NotFound />} />
        <Route
          path="/dashboardsuperviseur"
          element={<DashboardSuperviseur />}
        />
        <Route path="/ajoutcaissier" element={<AjoutCaissier />} />
        <Route path="/modifycaissier" element={<ModifyCaissier />} />
        <Route path="/caissiers" element={<Caissiers />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/deposits" element={< Deposits />} />
        <Route path="/reclamations" element={< Reclamations />} />
        <Route path="/" element={<Login />} />
        <Route path="/profile" element={<ProfilePage />} />

        <Route path="/infoscaissier" element={<InfosCaissier />} />
        <Route path="/reapp" element={<ReapprovisionnementPage />} />
        <Route path="/appel" element={<AppelFonPage />} />
        <Route path="/listesuperviseursmaster" element={<Listesuperviseursmaster />} />
        <Route path="/ajoutsuperviseur" element={<Ajoutsuperviseur />} />
        <Route path="/infosuperviseur" element={<Infosuperviseur />} />

      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
