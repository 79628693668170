import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder8: "rounded-radius8",
  RoundedBorder16: "rounded-radius16",
  RoundedBorder5: "rounded-radius5",
  icbRoundedBorder8: "rounded-radius8",
  icbCircleBorder16: "rounded-radius16",
};
const variants = {
  FillIndigoA700: "bg-indigo_A700 text-white_A701",
  FillGray50: "bg-gray_50 text-black_900",
  FillBluegray101: "bg-bluegray_101 text-black_900_7f",
  FillRed600: "bg-red_600 text-white_A701",
  FillLightgreen60059: "bg-light_green_600_59 text-light_green_800_b2",
  icbFillIndigoA70033: "bg-indigo_A700_33",
  icbFillGray200: "bg-gray_200",
  icbFillGray201: "bg-gray_201",
  icbFillIndigoA700: "bg-indigo_A700",
  icbFillWhiteA701: "bg-white_A701",
  icbFillCyan500: "bg-cyan_500",
};
const sizes = {
  sm: "lg:p-[5px] xl:p-[6px] 2xl:p-[7px] p-[8px] 3xl:p-[9px]",
  md: "xl:p-[10px] 2xl:p-[11px] p-[12px] 3xl:p-[13px] lg:p-[8px]",
  lg: "lg:p-[14px] xl:p-[17px] 2xl:p-[20px] p-[21px] 3xl:p-[24px]",
  xl: "lg:p-[16px] xl:p-[20px] 2xl:p-[22px] p-[24px] 3xl:p-[27px]",
  "2xl": "lg:p-[23px] xl:p-[29px] 2xl:p-[33px] p-[35px] 3xl:p-[40px]",
  smIcn: "p-[4px]",
  mdIcn: "p-[10px] 3xl:p-[11px] lg:p-[6px] xl:p-[8px] 2xl:p-[9px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} common-button `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder8",
    "RoundedBorder16",
    "RoundedBorder5",
    "icbRoundedBorder8",
    "icbCircleBorder16",
  ]),
  variant: PropTypes.oneOf([
    "FillIndigoA700",
    "FillGray50",
    "FillBluegray101",
    "FillRed600",
    "FillLightgreen60059",
    "icbFillIndigoA70033",
    "icbFillGray200",
    "icbFillGray201",
    "icbFillIndigoA700",
    "icbFillWhiteA701",
    "icbFillCyan500",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl", "smIcn", "mdIcn"]),
};
Button.defaultProps = {
  className: "",
  shape: "icbRoundedBorder8",
  variant: "FillIndigoA700",
  size: "mdIcn",
};

export { Button };
