import React, { useState, useEffect } from "react";
import { caissier } from "../../actions/create-caissier";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/user.service";
import Form from "react-validation/build/form";
import Dropdown from "components/Dropdown";
import { Navigate } from 'react-router-dom';
import { Modal } from 'antd';
import { Upload } from 'antd';
import { EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';
//import ImgCrop from 'antd-img-crop';
// import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
// import {

//   Upload,
// } from 'antd';

import {
  Column,
  Row,
  Button,
  Img,
  Text,
  Line,
  Input,
  List,
  Stack,
} from "components";
import Sidebar from "components/Sidebar";
import { useNavigate } from "react-router-dom";

const AjoutCaissierPage = () => {


  ////
  const { isLoggedIn } = useSelector(state => state.auth);
  ////

  const { user: currentUser } = useSelector((state) => state.auth);
  const [balance, setBalance] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  let calssSoled = true;
  // const [file, setFile] = useState(null);
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [fileName, setFileName] = useState("");

  const saveFile = (e) => {
    const image = e.target.files[0]
    setFile(image);
    setFileName(e.target.files[0].name);
  };

  const saveFile1 = (e) => {
    const image = e.target.files[0]
    setFile1(image);
    setFileName(e.target.files[0].name);
  };

  const [previewImage, setPreviewImage] = useState('');

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    setPreviewImage(file)
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const [showBalanceIcon, setShowBalanceIcon] = useState(false);

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }



  // const userId = currentUser.response.user.id

  console.log('fileList . ', fileList[0]);

  const classNameSolde = "text-amber_A700 font-montserrat font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]";
  const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";
  if (currentUser == null) {
    <Navigate to="/dashboardsuperviseur" />;

  }
  useEffect(() => {
    if (currentUser) {
      UserService.getBalance(currentUser.response.user.id).then(
        (response) => {
          setBalance(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setBalance(_content);
        }
      );
    }
  }, []);




  const navigate = useNavigate();

  // const rep = JSON.parse(localStorage.getItem('user'));
  // const created_by = rep.response.user.id
  const [firstName, setfirstName] = React.useState("");
  const [lastName, setlastName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [adresse, setAdresse] = React.useState("");

  const [error1, setError1] = useState(null);
  const [phone, setPhone] = React.useState("");
  const [CNI, setCNI] = React.useState("");

  const [error, setError] = useState(null);
  const [email, setEmail] = React.useState("");

  //
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidPhone(phone) {
    return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/.test(phone);
  }

  //

  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();

  const onChangefirstName = (e) => {
    const firstName = e.target.value;
    setfirstName(firstName);
  };

  const onChangelastName = (e) => {
    const lastName = e.target.value;
    setlastName(lastName);
  };


  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeAdresse = (e) => {
    const adresse = e.target.value;
    setAdresse(adresse);
  };

  const onChangePhone = (e) => {
    if (!isValidPhone(e.target.value)) {
      setError1('Number invalid');
    } else {
      setError1(null);
    }
    const phone = e.target.value;
    setPhone(phone);
  };
  const onChangeCNI = (e) => {
    const CNI = e.target.value;
    setCNI(CNI);
  };

  const onChangeEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }


    const email = e.target.value;
    setEmail(email);
  };

  // const OnchangeWahab = (e)=>{
  //   setFile(e.target.files[0])
  // }

  //console.log('previewImage > ',file, fileName);




  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessful(false);
    dispatch(caissier(firstName, lastName, username, phone, CNI, email, adresse, currentUser.response.user.id, file, file1))
      .then(() => {
        setIsModalOpen(true);
        // setSuccessful(true);
         navigate("/caissiers");
        //   window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
      });
  };


  // const response = JSON.parse(localStorage.getItem('user'));
  // const use = response.response.user.username
  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false
  }



  function handleNavigate28() {
    navigate("/caissiers");
  }
  function handleNavigate29() {
    navigate("/dashboardsuperviseur");
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const isEnabled = firstName.length > 0 && lastName.length > 0 && username.length > 0 && phone.length > 0 && CNI.length > 0 && email.length > 0 && adresse.length > 0;

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {

    return (
      <>
        <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
          <Row className=" justify-between w-[100%]">
            <Sidebar className="w-[18%]" onDashboardClick={handleNavigate29} />
            <Column className="bg-white_A700 items-center lg:py-[25px] xl:py-[32px] 2xl:py-[36px] 3xl:py-[43px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
              <Column className="items-center justify-start xl:mb-[115px] 2xl:mb-[129px] 3xl:mb-[155px] lg:mb-[92px] w-[100%]">
                <Row className="items-start w-[92%]">
                  <Button
                    className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center my-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    size="smIcn"
                    variant="icbFillGray201"
                  >
                    <Img
                      src="images/img_arrowleft.svg"
                      className="flex items-center justify-center"
                      onClick={handleNavigate28}
                      alt="arrowleft"
                    />
                  </Button>
                  <Text
                    className="font-montserrat font-semibold lg:ml-[20px] xl:ml-[26px] 2xl:ml-[29px] 3xl:ml-[35px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] text-black_900 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    Ajouter un caissier
                  </Text>
                  {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[50%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}
             <span  className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px]"><EyeOutlined onClick={handleBalanceIconClick}   /></span> 
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[50%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
               <span className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "><EyeInvisibleOutlined onClick={handleBalanceIconClick} /></span>
            </Row>
             )}
                  {/* <Text
                    className="font-bold font-inter mb-[1px] lg:ml-[152px] xl:ml-[190px] 2xl:ml-[214px] 3xl:ml-[257px] text-black_900 w-[auto]"
                    as="h4"
                    variant="h4"
                  >
                    <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                      Solde{" "}
                    </span>
                    <span className={calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"} >
                      {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                    </span>
                  </Text>
                  <Img
                    src="images/img_ueyeslash.svg"
                    className="ueyeslash"
                    alt="ueyeslash"
                  /> */}
                </Row>
                <Line className="bg-black_900_19 h-[1px] lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[100%]" />
                <Column className="justify-start lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[53px] w-[93%]">
                  <Form >
                    <Text
                      className="text-black_900_cc w-[auto]"
                      as="h6"
                      variant="h6"

                    >
                      <span style={{ color: "red" }}>*</span> Nom du Caissier
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupSeventeen"
                      value={lastName}
                      onChange={onChangelastName}
                      placeholder="Nom de famille"
                    ></Input>
                    <br />
                    <Text
                      className="text-black_900_cc w-[auto]"
                      as="h6"
                      variant="h6"
                    >
                      <span style={{ color: "red" }}>*</span> Prenom du caissier
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupSeventeen"
                      value={firstName}
                      onChange={onChangefirstName}

                      placeholder="Prenom"
                    ></Input>


                    <br />
                    <Text
                      className="text-black_900_cc w-[auto]"
                      as="h6"
                      variant="h6"
                    >
                      <span style={{ color: "red" }}>*</span> Nom d'utilisateur
                    </Text>

                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupSeventeen"
                      value={username}
                      onChange={onChangeUsername}
                      placeholder="Nom d'utilisateur"
                    ></Input>
                    <Text className="labelCounter" as="h6" variant="h6">
                      <span style={{ color: "red" }}>*</span> Numero du caissier
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupFourteen"
                      value={phone}
                      onChange={onChangePhone}
                      placeholder="7X XXX XX XX"
                    />{error1 && <h2 style={{ color: 'red' }}>{error1}</h2>}
                    <Text className="labelCounter" as="h6" variant="h6">
                      <span style={{ color: "red" }}>*</span> Adresse du caissier
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupSixteen"
                      value={adresse}
                      onChange={onChangeAdresse}
                      placeholder="Adresse"
                    ></Input>
                    <Text className="labelCounter" as="h6" variant="h6">
                      <span style={{ color: "red" }}>*</span> Piece d'identite
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupFourteen"
                      value={CNI}
                      onChange={onChangeCNI}
                      placeholder="Numero piece"
                    >
                    </Input>

                    {/* <Upload
                      action= ""
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                     
                    >
                      {fileList.length < 5 && '+ Upload'}
                    </Upload> */}

                    <Text className="labelCounter" as="h6" variant="h6">
                      <span style={{ color: "red" }}>*</span> Photo Piece d'identite(Recto et Verso)
                    </Text>
                    <br />
                    <input style={{ color: "red" }} type="file" onChange={saveFile} />

                    <input style={{ color: "red" }} type="file" onChange={saveFile1} />

                    <Text className="labelCounter" as="h6" variant="h6">
                      <span style={{ color: "red" }}>*</span> EMAIL
                    </Text>
                    <Input
                      className="placeholder:text-black_900_b2 GroupSeventeen"
                      wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                      name="GroupFourteen"
                      value={email}
                      onChange={onChangeEmail}
                      placeholder="user@gmail.com"

                    /> {error && <h2 style={{ color: 'red' }}>{error}</h2>}


                    </Form>

                    <Button
                      className="common-pointer font-semibold lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                      shape="RoundedBorder8"
                      size="lg"
                      disabled={!isEnabled}
                      onClick = {showModal}
                    >
                      Ajouter le caissier
                    </Button>
                  
                </Column>
              </Column>
            </Column>
            <Modal title="Informations sur le superviseur que vous souhaitez créer" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>


              <Text className="Numrodubnf" as="h5" variant="h5">
                Nom Superviseur:
              </Text>
              <b><Text className="Numrodubnf" >
                {lastName}
              </Text></b>
              <br />
              <br />

              <Text className="Numrodubnf" as="h5" variant="h5">
                Prenom Superviseur:
              </Text>
              <b><Text className="Numrodubnf" >
                {firstName}
              </Text></b>

              <br />
              <br />

              <Text className="Numrodubnf" as="h5" variant="h5">
                Nom d'utillisateur:
              </Text>
              <b><Text className="Numrodubnf"  >
                {username}
              </Text></b>
              <br />
              <br />

              <Text className="Numrodubnf" as="h5" variant="h5">
                Numero du superviseur client:
              </Text>
              <b><Text className="Numrodubnf">
                {phone}
              </Text></b>
              <br />
              <br />

              <Text className="Numrodubnf" as="h5" variant="h5">
                Adresse du superviseur client:
              </Text>
              <b><Text className="Numrodubnf"  >
                {adresse}
              </Text></b>
              <br />
              <br />

              <Text className="Numrodubnf" as="h5" variant="h5">
                CNI:
              </Text>
              <b><Text className="Numrodubnf" >
                {CNI}
              </Text></b>
              <br />
              <br />

              <Text className="email" as="h5" variant="h5">
                EMAIL:
              </Text>

              <b><Text className="email"   >
                {email}
              </Text></b>

              <br />

              <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
                <Button
                  className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"

                  shape="RoundedBorder8"

                  variant="FillBluegray100"
                  onClick={handleOk}
                >
                  Annuler
                </Button>
                <Button

                  className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                  shape="RoundedBorder8"
                  onClick={handleSubmit}

                  disabled={successful}>
                  {successful && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  Confirmer
                </Button>
              </Row>
            </Modal>

            <Column className="items-center w-[15%]">
              <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                {/* <Button
                className="flex items-center justify-center uexchange_One"
                variant="icbFillGray200"
              >
                <Img
                  src="images/img_settings.svg"
                  className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                  alt="settings"
                />
              </Button> */}
                <Dropdown color="white" />
                <Text
                  className="text-bluegray_600 font-semibold text-black_900 w-[auto]"
                  variant="body1"
                >
                  <strong>{currentUser ? (currentUser.response.user.username) : null}</strong>
                </Text>
                <Img
                  src="images/img_ellipse1.png"
                  className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                  alt="EllipseOne"
                />
              </Row>
              <List
                className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                orientation="vertical"
              >
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorTwo"
                        alt="VectorTwo"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorOne"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorThree"
                        alt="VectorThree"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorFour"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
              </List>
              <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                <Text className="columnfluxdetransac_two" variant="body2">
                  Flux de transactions
                </Text>
                <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                  <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                    <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                      <Row className="items-start justify-between w-[100%]">
                        <Text className="Thirty" variant="body2">
                          30%
                        </Text>
                        <Text className="Forty" variant="body2">
                          40%
                        </Text>
                      </Row>
                      <Text className="columnthirty" variant="body2">
                        30%
                      </Text>
                    </Column>
                  </Stack>
                </Column>
              </Column>
              <Button
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                  />
                }
                shape="RoundedBorder8"
                size="lg"
              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Réapprovisionner
                </div>
              </Button>
            </Column>

          </Row>
        </Column>
      </>
    );
  }
};

export default AjoutCaissierPage;
