import React from "react";

import { Column, Row, Button, Img, Text, Line, List, Stack } from "components";
import Sidebar1 from "components/Sidebar";
import { useNavigate } from "react-router-dom";
// import Chat from "components/Chat";

const ListesuperviseursmasterPage = () => {
  const navigate = useNavigate();

  function handleNavigate14() {
    navigate("/infosuperviseur");
  }
  function handleNavigate15() {
    navigate("/infosuperviseur");
  }
  function handleNavigate19() {
    navigate("/infosuperviseur");
  }
  function handleNavigate20() {
    navigate("/infosuperviseur");
  }
  function handleNavigate21() {
    navigate("/infosuperviseur");
  }
  function handleNavigate22() {
    navigate("/infosuperviseur");
  }
  function handleNavigate23() {
    navigate("/ajoutsuperviseur");
  }
  function handleNavigate24() {
    navigate("/infosuperviseur");
  }
  function handleNavigate25() {
    navigate("/infosuperviseur");
  }
  function handleNavigate26() {
    navigate("/infosuperviseur");
  }
  function handleNavigate27() {
    navigate("/infosuperviseur");
  }

  function handleNavigate30() {
    navigate("/dashboardsuperviseur");
  }

  return (
    <>
      <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <Row className="items-center justify-between w-[98%]">
          <Sidebar1 className="w-[18%]" />
          <Column className="bg-white_A700 items-center py-[4px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
            <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
              <Row className="items-start w-[92%]">
                <Button
                  className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center my-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                  size="smIcn"
                  variant="icbFillGray201"
                >
                  <Img
                    src="images/img_arrowleft.svg"
                    className="flex items-center justify-center"
                    onClick={handleNavigate30}
                    alt="arrowleft"
                  />
                </Button>
                <Text
                  className="font-montserrat font-semibold lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] text-black_900 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  superviseurs
                </Text>
                <Text
                  className="font-bold font-inter mb-[1px] lg:ml-[201px] xl:ml-[251px] 2xl:ml-[283px] 3xl:ml-[339px] text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                    Solde{" "}
                  </span>
                  <span className="text-bluegray_600 font-montserrat lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                    1.000.000 Fcfa
                  </span>
                </Text>
                <Img
                  src="images/img_ueyeslash.svg"
                  className="ueyeslash"
                  alt="ueyeslash"
                />
              </Row>
              <Line className="bg-black_900_19 h-[1px] lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[100%]" />
              <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[93%]">
                <Row className="items-start w-[99%]">
                  <Img
                    src="images/img_user_44X44.svg"
                    className="xl:mt-[11px] 2xl:mt-[13px] 3xl:mt-[16px] lg:mt-[9px] uexchange_One"
                    alt="user"
                  />
                  <Text
                    className="text-indigo_A700 w-[auto]"
                    as="h2"
                    variant="h2"
                  >
                    32
                  </Text>
                  <Text
                    className="xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] text-black_900_cc w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    superviseurs
                  </Text>
                  <Button
                    className="2xl:ml-[257px] 3xl:ml-[308px] flex items-center justify-center lg:ml-[182px] text-center w-[38%] xl:ml-[228px]"
                    onClick={handleNavigate23}
                    leftIcon={
                      <Img
                        src="images/img_plus.svg"
                        className="text-center lg:w-[21px] lg:h-[22px] lg:mr-[10px] xl:w-[27px] xl:h-[28px] xl:mr-[12px] 2xl:w-[30px] 2xl:h-[31px] 2xl:mr-[14px] 3xl:w-[36px] 3xl:h-[37px] 3xl:mr-[17px]"
                        alt="plus"
                      />
                    }
                    shape="RoundedBorder8"
                    size="lg"
                  >
                    <div className="common-pointer bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                      Ajouter un superviseurs
                    </div>
                  </Button>
                </Row>
                <List
                  className="gap-[0] min-h-[auto] lg:mt-[31px] xl:mt-[38px] 2xl:mt-[43px] 3xl:mt-[52px] w-[100%]"
                  orientation="vertical"
                >
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket One"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate24}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 caissier
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[48px] xl:ml-[61px] 2xl:ml-[68px] 3xl:ml-[82px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Two"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate19}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[43px] xl:ml-[54px] 2xl:ml-[60px] 3xl:ml-[73px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Three"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate14}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Fann Résidence
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      28 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[44px] xl:ml-[55px] 2xl:ml-[61px] 3xl:ml-[74px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Four"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate25}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[43px] xl:ml-[54px] 2xl:ml-[60px] 3xl:ml-[73px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Five"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate20}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[46px] xl:ml-[57px] 2xl:ml-[64px] 3xl:ml-[77px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Six"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate15}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Fann Résidence
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      28 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[44px] xl:ml-[55px] 2xl:ml-[61px] 3xl:ml-[74px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Seven"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate26}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[43px] xl:ml-[54px] 2xl:ml-[60px] 3xl:ml-[73px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Eight"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate21}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[46px] xl:ml-[57px] 2xl:ml-[64px] 3xl:ml-[77px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Nine"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate27}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[43px] xl:ml-[54px] 2xl:ml-[60px] 3xl:ml-[73px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Ten"
                      />
                    </Button>
                    <Text
                      className="common-pointer CashPay"
                      variant="body1"
                      onClick={handleNavigate22}
                    >
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 caissiers
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[46px] xl:ml-[57px] 2xl:ml-[64px] 3xl:ml-[77px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                </List>



              </Column>
            </Column>
          </Column>
          <Column className="items-center w-[15%]">
            <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
              <Button
                className="flex items-center justify-center uexchange_One"
                variant="icbFillGray200"
              >
                <Img
                  src="images/img_settings.svg"
                  className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                  alt="settings"
                />
              </Button>
              <Text
                className="font-semibold text-black_900 w-[auto]"
                variant="body1"
              >
                Boubou
              </Text>
              <Img
                src="images/img_ellipse1.png"
                className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                alt="EllipseOne"
              />
            </Row>
            <List
              className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
              orientation="vertical"
            >
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorTwo"
                      alt="VectorTwo"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorOne"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorThree"
                      alt="VectorThree"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorFour"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
            </List>
            <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
              <Text className="columnfluxdetransac_two" variant="body2">
                Flux de transactions
              </Text>
              <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                  <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                    <Row className="items-start justify-between w-[100%]">
                      <Text className="Thirty" variant="body2">
                        30%
                      </Text>
                      <Text className="Forty" variant="body2">
                        40%
                      </Text>
                    </Row>
                    <Text className="columnthirty" variant="body2">
                      30%
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Button
              className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
              leftIcon={
                <Img
                  src="images/img_camera_24X24.svg"
                  className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                  alt="camera"
                />
              }
              shape="RoundedBorder8"
              size="lg"
            >
              <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                Réapprovisionner
              </div>
            </Button>
          </Column>
        </Row>
      </Column>
    </>
  );
};

export default ListesuperviseursmasterPage;
