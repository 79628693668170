import { Space, Modal } from 'antd';
import { Button,Row } from "components";
import React, { useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import StatusActions from './StatusActions';

import { FcInfo, FcPrint } from "react-icons/fc";

function printDocument() {
  const input = document.getElementById('divToPrint1');
  html2canvas(input)
    .then((canvas) => {
      //const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      //pdf.addImage(imgData, 'JPEG', 0, 0);
      //pdf.output('dataurlnewwindow');
      pdf.save("recu.pdf");
    })
    ;
}



export default function BankerActions(props) {

  const [isModalOpen0, setIsModalOpen0] = useState(false);
  const [successful, setSuccessful] = useState(false);


  const showModal = () => {
    setIsModalOpen0(true);
  };

  const handleOk0 = () => {
    setIsModalOpen0(false);
  };

  const handleCancel0 = () => {
    setIsModalOpen0(false);
  };


  function showInfosCaissier(record) {

    setIsModalOpen0(true);
    // Modal.info({
    //   title: 'Details du transaction',
    //   content: (
    //     <div>
    //       <div id="divToPrint1" style={{
    //         marginTop: 10, backgroundColor: '#f5f5f5'
    //       }}>
  
    //         <p>RECU : {record.type + " " + record.service}</p>
    //         <p>Nom :  {record.first_name + " " + record.last_name}</p>
    //         <p>Numero : {record.numero}</p>
    //         <p>Prix : {record.montant}</p>
    //         <p>Date :  {record.createdAt}</p>
    //         <p>Status :  <StatusActions record={record} /></p>
    //         <br />
  
  
    //       </div>
    //       <br />
    //       <br />
    //       {/* <Button
    //              icon={<FcPrint size="25px" />} 
    //              onClick={() => { printDocument() }}/> */}
    //     </div>
    //   )
    // });
  }

  const [loading] = useState(false)

  if (loading) return <Button type="primary" shape="circle" loading />
  return (

    <Space size="small">
      {/* <button onClick={() => { showInfosCaissier(props?.record) }} ><FcInfo size="25px" /></button> */}
      <button onClick={() => { showInfosCaissier() }} ><FcInfo size="25px" /></button>
      <Modal size= "small"  title={<div style={{  display:"flex" }}><FcInfo size="25px" /><p>Information du Caissier</p></div>}  footer={null} open={isModalOpen0} onOk={handleOk0} closable={false}>
      <div>
        <div id="divToPrint1" style={{
          marginTop: 10, backgroundColor: '#f5f5f5'
        }}>
         
        {/* <p style={{  margin: "auto" }}> */}
        <p>RECU : <b>{props?.record.type + " " + props?.record.service}</b></p>
        {/* <p>Nom :  <b>{props?.record.username + " " + props?.record.username}</b></p> */}
        <p>Numero : <b>{props?.record.numero}</b></p>
        <p>Prix : <b>{props?.record.montant}</b></p>
        <p>Date :  <b>{props?.record.createdAt}</b></p>
        <p>Status :  <b><StatusActions record={props?.record} /></b></p>


        
        {/* </p> */}

      
      </div>
      </div>

      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[100%]">
         <div   style={{width:":85%"}} ></div>
         
          <Button

  
            onClick={handleOk0}
            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            OK
          </Button>
        </Row>

    </Modal>


    </Space>


  )
}