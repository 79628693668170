import React, { useState, useEffect } from "react";
//import LiveSearchFilter from "components/Recherche/LiveSearchFilter.js"
import { Column, Row, Button, Img, Text, Line, List, Stack } from "components";
import Sidebar from "components/Sidebar";
import { Navigate, useNavigate } from 'react-router-dom';
// import { Input } from 'antd';
// import List1 from "components/List1";
import Dropdown from "components/Dropdown";
import ListDemande from "components/List_example/ListDemande";
import { verifyPhoneOtp } from "../../actions/user";
import { useSelector, useDispatch } from "react-redux";
import UserService from "../../services/user.service";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Modal } from 'antd';
import { EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';
// const { Search } = Input;



const CaissiersPage = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [balance, setBalance] = useState("");


  let calssSoled = true;

  // const userId = currentUser.response.user.id


  const [showBalanceIcon, setShowBalanceIcon] = useState(false);

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }


  const classNameSolde = "text-amber_A700 font-montserrat font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]";
  const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";
  if (currentUser == null) {
    <Navigate to="/dashboardsuperviseur" />;

  }
  useEffect(() => {
    if (currentUser) {
      UserService.getBalance(currentUser.response.user.id).then(
        (response) => {
          setBalance(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setBalance(_content);
        }
      );
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [montant, setMontant] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();




  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const [content, setContent] = useState("");


  const generate = () => {

    setIsModalOpen(true);

  };

  if (!currentUser) {
    return <Navigate to="/dashboardsuperviseur" />;
  }
  const handleSubmit = (e) => {

    e.preventDefault();

    setSuccessful(false);

    dispatch(verifyPhoneOtp(currentUser.response.user.id, otp))
      .then(() => {

        // dispatch(create(montant, user,senderId,receiverId))
        setSuccessful(true);
        navigate("/ajoutcaissier");
        window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
        setOtp("")

      });

  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false
  }



  // const response = JSON.parse(localStorage.getItem('user'));
  // const username = response.response.user.username
  // const state  = response.response

  //  console.log("state : ",state);

  // }
  function handleNavigate20() {
    navigate("/dashboardsuperviseur");
  }

  function handleNavigate16() {
    navigate("/ajoutcaissier");
  }

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {


    return (
      <>
        <Column className="bg-bluegray_50  items-center justify-start mx-[auto] w-[100%]">
          <Row className=" justify-between w-[100%]">
            <Sidebar className="w-[18%]" />
            <Column className="bg-white_A700 items-center lg:py-[4px] 2xl:py-[5px] xl:py-[5px] 3xl:py-[6px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
              <Column className="items-center justify-start lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] w-[100%]">
                <Row className="items-start w-[92%]">
                  <Button
                    className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    size="smIcn"
                    variant="icbFillGray201"
                  >
                    <Img
                      src="images/img_arrowleft.svg"
                      className="flex items-center justify-center"
                      onClick={handleNavigate20}
                      alt="arrowleft"
                    />
                  </Button>
                  <Text className="Modifieruncai" as="h5" variant="h5">
                    Caisses
                  </Text>
                  {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[70%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}
               <span  className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px]"><EyeOutlined onClick={handleBalanceIconClick}   /></span>
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[70%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
               <span className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "><EyeInvisibleOutlined onClick={handleBalanceIconClick} /></span>
            </Row>
             )}
                </Row>
                <Line className="bg-black_900_19 h-[1px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]" />
                <Column className="items-center justify-start lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[93%]">
                  <Row className="items-start w-[99%]">
                    <Img
                      src="images/img_camera_44X44.svg"
                      className="xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] uexchange_One"
                      alt="camera"
                    />
                    <Text
                      //  className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]"
                      className="text-bluegray_600"
                      as="h4"
                      variant="h4"
                    //  style={{ color: 'blue' }}
                    >
                      Caissiers
                    </Text>

                    <Button
                      className="2xl:ml-[320px] 3xl:ml-[384px] flex items-center justify-center lg:ml-[227px] text-center w-[35%] xl:ml-[284px]"
                      // onClick={handleNavigate16}
                      onClick={generate}
                      leftIcon={
                        <Img
                          src="images/img_plus.svg"
                          className="text-center lg:w-[21px] lg:h-[22px] lg:mr-[10px] xl:w-[27px] xl:h-[28px] xl:mr-[12px] 2xl:w-[30px] 2xl:h-[31px] 2xl:mr-[14px] 3xl:w-[36px] 3xl:h-[37px] 3xl:mr-[17px]"
                          alt="plus"
                        />
                      }
                      shape="RoundedBorder8"
                      size="md"
                    >
                      <div className="common-pointer bg-transparent font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Ajouter une caisse
                      </div>

                    </Button>



                  </Row>
                  <Modal title="Ajouter un caissier" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>

                    {message && (
                      <div className="form-group">
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                          {message}
                        </div>
                      </div>
                    )}


                    <div class=" p-2 text-center">
                      <Img
                        src="images/coolupay.png"
                        className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[80%]"
                        alt="logobbsOne"
                      />
                      <h4>Veuillez entrer votre code OTP</h4>


                      <br />
                      <div className="absolute content-center items-center  right-[-10] top-[20] ">
                        <OTPInput
                          inputStyles={{
                            width: "20%",
                            height: "30px",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "bold"
                          }}
                          value={otp}
                          onChange={setOtp}
                          autoFocus
                          OTPLength={4}
                          otpType="number" disabled={false} />
                        <div></div>
                      </div>
                    </div>
                    <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
                      <Button
                        className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"

                        shape="RoundedBorder8"

                        variant="FillBluegray100"
                        onClick={handleOk}
                      >
                        Annuler
                      </Button>
                      <Button

                        className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                        shape="RoundedBorder8"
                        onClick={handleSubmit}

                        disabled={successful}>
                        {successful && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Confirmer
                      </Button>
                    </Row>
                  </Modal>



                  <br />

                  <Row className="bg-white_A700 items-center justify-between lg:p-[20px] xl:p-[13px] 2xl:p-[20px] 3xl:p-[18px] rounded-radius16 w-[100%]">

                    <ListDemande state={currentUser ? (currentUser.response) : null} />
                    {/* <List1 /> */}
                  </Row>
                </Column>
              </Column>
            </Column>
            <Column className="items-center w-[15%]">
              <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                {/* <Button
                className="flex items-center justify-center uexchange_One"
                variant="icbFillGray200"
              >
                <Img
                  src="images/img_settings.svg"
                  className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                  alt="settings"
                />
              </Button> */}
                <Dropdown color="white" />
                <Text
                  className="text-bluegray_600 font-semibold text-black_900 w-[auto]"
                  variant="body1"
                >
                  <strong>{currentUser ? (currentUser.response.user.username) : null}</strong>
                </Text>
                <Img
                  src="images/img_ellipse1.png"
                  className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                  alt="EllipseOne"
                />
              </Row>
              <List
                className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                orientation="vertical"
              >
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorTwo"
                        alt="VectorTwo"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorOne"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorThree"
                        alt="VectorThree"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorFour"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
              </List>
              <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                <Text className="columnfluxdetransac_two" variant="body2">
                  Flux de transactions
                </Text>
                <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                  <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                    <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                      <Row className="items-start justify-between w-[100%]">
                        <Text className="Thirty" variant="body2">
                          30%
                        </Text>
                        <Text className="Forty" variant="body2">
                          40%
                        </Text>
                      </Row>
                      <Text className="columnthirty" variant="body2">
                        30%
                      </Text>
                    </Column>
                  </Stack>
                </Column>
              </Column>
              <Button
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                  />
                }
                shape="RoundedBorder8"
                size="lg"
              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Réapprovisionner
                </div>
              </Button>
            </Column>
          </Row>
        </Column>
      </>
    );
  }
};

export default CaissiersPage;



