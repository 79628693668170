import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;

//creation d'une reapprovisionnement
const create = (montant, user, senderId, receiverId) => {
  const data = JSON.stringify({
    "amount": montant,
    "comment": "Demande de réapprovisionnement",
    "user": user,
    "senderId": senderId,
    "receiverId": receiverId
  });

  var config = {
    method: 'post',
    url: API_URL + '/create',
    headers: authHeader(),
    data: data
  };

  return axios(config)

}

//liste des reapprovisionnement senderId
const getAllReap = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "senderId": userId,
  });


  const config = {
    method: 'post',
    url: API_URL + "/getAll",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};


/// liste des reapprovisionnement des receiverId

const getAllReap_ = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "receiverId": userId,
  });


  const config = {
    method: 'post',
    url: API_URL + "/getAllReceiverId",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create, getAllReap, getAllReap_
};