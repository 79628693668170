import Dropdown from "components/Dropdown";
import React, { useState, useEffect } from "react";
import { Column, Row, Img, Text, Button, List, Stack,Input } from "components";
import { EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';
import Sidebar from "components/Sidebar";
// import Chat from "components/Chat";
import ButtonChat from "components/ButtonChat";
import ListReapp from "components/ListReapp";
import ListTransR from "components/ListTransR";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import UserService from "../../services/user.service";
// import isLoggedIn from "../../reducers/auth"
// import Download from "components/Download";


const DashboardSuperviseurPage = (props) => {
  const { isLoggedIn } = useSelector(state => state.auth);


  let calssSoled = true;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //
  const { user: currentUser } = useSelector((state) => state.auth);

  const [balance, setBalance] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // if (!currentUser || !isLoggedIn) {
  //   navigate("/");
  // }


  // const userId = currentUser.response.user.id

  const [showBalanceIcon, setShowBalanceIcon] = useState(false);

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }


  const classNameSolde = "text-amber_A700 font-montserrat font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]";
  const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";
  if (currentUser == null) {
    <Navigate to="/dashboardsuperviseur" />;

  }
  //   if (!currentUser || !isLoggedIn) {
  //     navigate("/");
  //  }
  useEffect(() => {
    if (currentUser) {
      UserService.getBalance(currentUser.response.user.id).then(
        (response) => {
          setBalance(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setBalance(_content);
        }
      );
    }

  }, []);
  //


  // const { user: currentUser } = useSelector((state) => state.auth);
  const [count, setCount] = useState("");
  const [countCaissier, setCountCaissier] = useState("");
  const [countReclamation, setCountReclamation] = useState("");
  console.log("user :", currentUser);

  // const userId = currentUser.response.user.id



  const classNameCount_ = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]";
  // const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]";
  const classNameSolde__ = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]";
  if (currentUser == null) {
    <Navigate to="/dashboardsuperviseur" />;

  }
  useEffect(() => {
    if (currentUser) {
      UserService.count(currentUser.response.user.id).then(
        (response) => {
          setCount(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setCount(_content);
        }
      );

      UserService.countCaissier(currentUser.response.user.id).then(
        (response) => {
          setCountCaissier(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setCountCaissier(_content);
        }
      );


      UserService.countReclamation(currentUser.response.user.id).then(
        (response) => {
          setCountReclamation(response.data);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setCountReclamation(_content);
        }
      );

    }


  }, []);
  // const getcountCaissier = ()=>{
  //   UserService.countCaissier(userId).then(
  //     (response) => {
  //       setCountCaissier(response.data);
  //     },
  //     (error) => {
  //       const _content =
  //         (error.response && error.response.data) ||
  //         error.message ||
  //         error.toString();

  //         setCountCaissier(_content);
  //     }
  //   );
  // }
  if (100000 >= parseInt(balance.solde)) {
    calssSoled = false
  }




  const navigate = useNavigate();
  //const { state } = useLocation();
  // const response = JSON.parse(localStorage.getItem('user'));
  // const username = response.response.user.username
  // const state  = response.response





  function handleNavigate1(path) {
    navigate("/caissiers");
  }
  // function handleNavigate2() {
  //   navigate("/caissiers");
  // }
  function handleNavigate2() {
    navigate("/appel");
  }

  function handleNavigate3() {
    navigate("/transactions");
  }

  function handleNavigate4() {
    navigate("/reclamations");
  }

  function handleNavigate5() {
    navigate("/reapp");
  }
  // function handleNavigate7() {
  //   navigate("/listesuperviseursmaster");
  // }

  if (!isLoggedIn) {
    return <Navigate replace to="/" />;
  } else {




    return (
      <>
        <Column className="bg-bluegray_50  items-center justify-start mx-[auto] w-[100%]">
          <Row className=" justify-between w-[100%]">

            <Sidebar className="w-[18%]"
            />
            <Column className="w-[63%]">

              <Column className="bg-white_A700 font-montserrat items-center justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[1px] 3xl:p-[27px] rounded-radius12 shadow-bs2  w-[100%]">
                <Column className="justify-start lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[75%] items-center">

                {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
             <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
               <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                 Solde{" "}
               </span>
               <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             {/* <Img
               src="images/img_ueyeslash.svg"
               className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
               alt="ueyeslash"
               onClick={handleBalanceIconClick}
             /> */}

             <span  className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px]"><EyeOutlined onClick={handleBalanceIconClick}   /></span> 
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
              <Text className="text-black_900 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ?  "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              {/* <Img
                src="images/img_ueyeslash.svg"
                className="lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "
                alt="ueyeslash"
                onClick={handleBalanceIconClick}
              /> */}
             <span className="text-bluegray_600 lg:ml-[0px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[8px] "><EyeInvisibleOutlined onClick={handleBalanceIconClick} /></span> 
            </Row>
             )}
                  {/* <Row className="items-start w-[92%] items-center">

                    <Text
                      className="font-bold font-inter mb-[1px] lg:ml-[152px] xl:ml-[190px] 2xl:ml-[214px] 3xl:ml-[257px] text-black_900 w-[auto]"
                      as="h4"
                      variant="h4"
                    >
                      <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                        Solde{" "}
                      </span>
                      <span className={calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber-500 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]"} >
                        {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                      </span>

                    </Text>
                    <Img
                      src="images/img_ueyeslash.svg"
                      className="ueyeslash"
                      alt="ueyeslash"
                    />
                  </Row> */}
                </Column>
              </Column>


              <Row className="font-montserrat items-center justify-between lg:mt-[20px] xl:mt-[26px] 2xl:mt-[29px] 3xl:mt-[35px] w-[100%]">
                <Column
                  className="common-pointer bg-white_A700 lg:p-[12px] xl:p-[16px] 2xl:p-[18px] 3xl:p-[21px] rounded-radius12 shadow-bs2 w-[30%]"
                  onClick={handleNavigate1}


                >
                  <Column className="justify-start xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] mt-[4px] w-[61%]">
                    <Img
                      src="images/img_camera.svg"
                      className="ml-[1px] uexchange_One"
                      alt="camera"

                    />
                    <Row className="items-start justify-between lg:mt-[18px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[100%]">

                      <span className={classNameCount_}

                      >
                        {new Intl.NumberFormat('de-DE').format(countCaissier.count)}
                      </span>

                      <Text
                        className="font-semibold lg:mt-[15px] xl:mt-[19px] 2xl:mt-[21px] 3xl:mt-[26px] text-black_900_cc w-[auto]"
                        variant="body1"
                      // style={{ color: 'blue' }}
                      >
                        Caisses
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column
                  onClick={handleNavigate3}
                  className="bg-white_A700 lg:p-[12px] xl:p-[16px] 2xl:p-[18px] 3xl:p-[21px] rounded-radius12 shadow-bs2 w-[30%]">
                  <Column

                    className="justify-start xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] mt-[4px] w-[74%]">
                    <Img
                      src="images/img_uexchange_44X44.svg"
                      className="uexchange_One"
                      alt="uexchange One"
                    />
                    <Row

                      className="items-start lg:mt-[18px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[100%]">
                      <span className={_classNameSolde} >
                        {new Intl.NumberFormat('de-DE').format(count.sum)}

                      </span>
                      <Text
                        className="font-semibold lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] lg:mt-[14px] xl:mt-[18px] 2xl:mt-[20px] 3xl:mt-[25px] text-black_900_cc w-[auto]"
                        variant="body1"
                      >
                        Transactions
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column
                  onClick={handleNavigate4}
                  className="bg-white_A700 lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] rounded-radius12 shadow-bs2 w-[30%]">
                  <Column className="justify-start xl:ml-[11px] 2xl:ml-[12px] 3xl:ml-[14px] lg:ml-[8px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] w-[64%]">
                    <Img
                      src="images/img_warning_44X44.svg"
                      className="uexchange_One"
                      alt="warning One"
                    />
                    <Row className="items-start lg:mt-[18px] xl:mt-[23px] 2xl:mt-[26px] 3xl:mt-[32px] w-[100%]">
                      {/* <Text
                        className="text-indigo_A700 w-[auto]"
                        as="h2"
                        variant="h2"
                        style={{ color: 'blue' }}
                      >
                        24
                      </Text> */}
                      <span className={classNameSolde__} >
                        {new Intl.NumberFormat('de-DE').format(countReclamation.count)}

                      </span>
                      <Text
                        className="font-semibold lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] lg:mt-[15px] xl:mt-[19px] 2xl:mt-[21px] 3xl:mt-[26px] text-black_900_cc w-[auto]"
                        variant="body1"
                      >
                        Réclamations
                      </Text>
                    </Row>
                  </Column>
                </Column>

              </Row>
              {/* <Column className="bg-white_A700 font-montserrat items-center justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[1px] 3xl:p-[27px] rounded-radius12 shadow-bs2  w-[100%]">
                <Column className=" justify-start lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[98%]">
                  <Text
                    className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]"
                    as="h4"
                    variant="h4"
                  
                  >
                    Demandes de réapprovisionnement du superviseur
                  </Text>
                 
                  <br />
                  <ListReapp state={currentUser ? (currentUser.response):null}/>  
                   
                </Column>
              </Column> */}
              <Column className="bg-white_A700 font-montserrat items-center justify-start lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[15px] xl:p-[19px] 2xl:p-[3px] 3xl:p-[26px] rounded-radius12 shadow-bs2 w-[100%]">
                <Column className="justify-start mb-[1px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] w-[99%]">
                  <Text
                    className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]"
                    as="h2"
                    variant="h2"

                  >
                    Historique de reapprovisionnement de ces caissiers
                  </Text>

                  <br />
                  <ListTransR state={currentUser ? (currentUser.response) : null} />
                </Column>
              </Column>
            </Column>
            <Column className="items-center w-[15%]">
              <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
                {/* <Button
                  className="flex items-center justify-center uexchange_One"
                  variant="icbFillGray200"
                >
                  <Img
                    src="images/img_settings.svg"
                    className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                    alt="settings"
                  />
                </Button> */}
                <Dropdown color="white" />
                <Text
                  className="text-bluegray_600 font-semibold text-black_900 w-[auto]"
                  variant="body1"
                >
                  <strong>{currentUser ? (currentUser.response.user.username) : null}</strong>
                </Text>
                <Img
                  src="images/img_ellipse1.png"
                  className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                  alt="EllipseOne"
                />
              </Row>
              <List
                className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                orientation="vertical"
              >
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorTwo"
                        alt="VectorTwo"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorOne"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux des réclamations
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorThree"
                        alt="VectorThree"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorFour"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>

              </List>
              <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                <Text
                  className="font-medium lg:mr-[12px] xl:mr-[15px] 2xl:mr-[17px] 3xl:mr-[20px] mt-[4px] text-black_900 w-[auto]"
                  variant="body2"

                >
                  réapprovisionnements
                </Text>
                <Column className="items-center justify-start mt-[3px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                  <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                    <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                      <Row className="items-start justify-between w-[100%]">
                        <Text className="Thirty" variant="body2">
                          30%
                        </Text>
                        <Text className="Forty" variant="body2">
                          40%
                        </Text>
                      </Row>
                      <Text className="columnthirty" variant="body2">
                        30%
                      </Text>
                    </Column>
                  </Stack>
                </Column>
              </Column>
              {/* <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[100%]">
                  <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Chat in
                  </Text>
                  <Stack className="  w-[100%]">
                      <Img
                        src="images/chat.png"
                        className="ml-[1px] uexchange_One"
                        alt="camera"
                      />
                      
                    </Stack>
                  <br />
                   
                  <Row className=" rounded-radius16 w-[90%]">
                  <Chat />
                  </Row>
  
                 
                  </Column>
                </Column> */}

              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[100%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body1"
                  >
                    Chat in
                  </Text>
                  <Stack className="  w-[100%]">
                    <Img
                      src="images/chat.png"
                      className="ml-[1px] uexchange_One"
                      alt="camera"
                    />

                  </Stack>
                  <br />
                  <Row className=" rounded-radius16 w-[180%]">
                    <ButtonChat />
                  </Row>
                </Column>
              </Column>
              <Button
                onClick={handleNavigate5}
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[100%] xl:mt-[20px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                  />
                }
                shape="RoundedBorder8"
                size="lg"

              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Réapprovisionner
                </div>
              </Button>
              <Button
                onClick={handleNavigate2}
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[100%] xl:mt-[20px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                  />
                }
                shape="RoundedBorder8"
                size="lg"
              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Appel de Fond
                </div>
              </Button>
            </Column>
          </Row>
        </Column>
      </>
    );
  }

};

export default DashboardSuperviseurPage;
