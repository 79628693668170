import { Space } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { Alert } from 'antd';



export default function StatusActions(props) {

    const block = props?.record?.block
    const [loading] = useState(false)
    console.log(block)

    if (loading) return <Button type="primary" shape="circle" loading />
    if (block === false) return (
        <Alert style={{ border: 0 }} message="ACTIF" type="success" showIcon />


    )

    if (block === true) return (
        <Alert style={{ border: 0 }} message="BLOQUÉ" type="error" showIcon />
    )

    return (
        <Space size='middle' >

        </Space>
    )

}