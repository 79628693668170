import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Button, Input, Space, Table } from 'antd';
import React, { useRef, useState, useEffect } from "react";
import Highlighter from 'react-highlight-words';
import StatusActions from './StatusActions';
import BankerActions from './BankerActions';
import ServiceActions from '../ListTrans/ServiceActions';
import UserService from "../../services/user.service";
import moment from 'moment'

const { RangePicker } = DatePicker



const List1 = () => {
  let locale = {
    emptyText: 'Pas de reclamation',
  };

  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});


  const [content, setContent] = useState("");

  useEffect(() => {
    setLoggedIn(false)
    UserService.getAllReclamation().then(
      (response) => {
        setLoggedIn(true)
        setContent(response.data.response.reclamations);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);









  ///

  const handleChange = (pagination, filters, sorter) => {

    setFilteredInfo(filters);
    setSortedInfo(sorter);

  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button

            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#FFC069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  ///

  const columns = [
    {
      title: 'Service',
      dataIndex: 'transactions',
      key: 'service',
      width: '10%',
      render: (text, record) => {
        return <ServiceActions record={record} />
      },

    },


    {
      title: 'Nom',
      dataIndex: 'user',
      key: 'user',
      width: '15%',

      render: (text, record) => {

        return record?.user
      },

    },

    {
      title: 'Montant(FCFA)',
      dataIndex: 'amount',
      key: 'amount',
      width: '18%',

      render: (text, record) => {

        return record?.amount
      },

    },

    {
      title: 'Date',
      dataIndex: 'transactions',
      key: 'date',
      width: '25%',
      render: (text, record) => {

        return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
      },





    },
    {
      title: 'Status',
      dataIndex: 'transactions',
      key: 'status',
      width: '20%',
      render: (text, record) => {
        return <StatusActions record={record} />
      },


    },
    {
      title: 'Actions',
      dataIndex: 'transactions',
      key: 'Action',
      width: '15%',
      render: (text, record) => {
        return <BankerActions record={record} />
      }
    }
  ];
  return (
    <>
      {/* <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={setAgeSort}>Sort heure</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space> */}
      {/* console.log({startDate}+ " "+{endDate} ) */}

      <Table locale={locale} columns={columns} dataSource={content} pagination={{ pageSize: 5 }} onChange={handleChange} loading={!isLoggedIn} />
    </>
  );
};
export default List1;