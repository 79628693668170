import { Space } from 'antd';
import { Button, Img } from 'components/';
import React, { useState } from 'react';




export default function ServiceActions(props) {

    const icone = props?.record?.icone
    const [loading] = useState(false)
    // console.log(service)

    return (
        <Space size='middle' >
            <Button
                className="flex lg:h-[30px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] items-center justify-center lg:w-[29px] xl:w-[37px] 2xl:w-[41px] 3xl:w-[50px]"
                variant="icbFillGray100"
            >
                <Img
                    src="images/dp.png"
                    className="car"
                    alt="umoneywithdra"
                />
            </Button>

        </Space>
    )

}