import axios from "axios";
import authHeader from "./auth-header";
const API_URL = `${process.env.REACT_APP_API_URL}/decreaseSolde`;
// const API_URL = "http://localhost:4042/api/v1/updateSolde";

//update solde
const decreasesolde = (receiverId, senderId, solde) => {
    const data = JSON.stringify({
        "receiverId": receiverId,
        "senderId": senderId,
        "solde": solde

    });
    const config = {
        method: 'post',
        url: API_URL,
        headers: authHeader(),
        data: data
    };
    return axios(config)
};

// eslint-disable-next-line import/no-anonymous-default-export

export default {
    decreasesolde
}