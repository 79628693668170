import React from "react";

import { Column, Row, Button, Img, Text, Line, List, Stack } from "components";
import Sidebar1 from "components/Sidebar";
import { useNavigate } from "react-router-dom";

const InfosuperviseurPage = () => {
  const navigate = useNavigate();

  function handleNavigate4() {
    navigate("/dashboardsuperviseur");
  }
  return (
    <>
      <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <Row className="items-center justify-between w-[98%]">
          <Sidebar1 className="w-[18%]" onDashboardClick={handleNavigate4} />
          <Column className="bg-white_A700 items-center lg:pt-[25px] xl:pt-[32px] 2xl:pt-[36px] 3xl:pt-[43px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
            <Column className="items-center justify-start w-[100%]">
              <Row className="items-start w-[92%]">
                <Button
                  className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center my-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                  size="smIcn"
                  variant="icbFillGray201"
                >
                  <Img
                    src="images/img_arrowleft.svg"
                    className="flex items-center justify-center"
                    alt="arrowleft"
                  />
                </Button>
                <Text
                  className="font-montserrat font-semibold lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] text-black_900 w-[auto]"
                  as="h5"
                  variant="h5"
                >
                  Information superviseur
                </Text>
                <Text
                  className="font-bold font-inter mb-[1px] lg:ml-[105px] xl:ml-[132px] 2xl:ml-[148px] 3xl:ml-[178px] text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                    Solde{" "}
                  </span>
                  <span className="text-bluegray_600 font-montserrat lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]">
                    1.000.000 Fcfa
                  </span>
                </Text>
                <Img
                  src="images/img_ueyeslash.svg"
                  className="ueyeslash"
                  alt="ueyeslash"
                />
              </Row>
              <Line className="bg-black_900_19 h-[1px] lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[100%]" />
              <Column className="justify-start lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[53px] w-[93%]">
                <Row className="items-start w-[100%]">
                  <Img
                    src="images/img_user_44X44.svg"
                    className="lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] uexchange_One"
                    alt="user"
                  />
                  <Text
                    className="lg:ml-[15px] xl:ml-[19px] 2xl:ml-[21px] 3xl:ml-[26px] lg:mt-[12px] xl:mt-[16px] 2xl:mt-[18px] 3xl:mt-[21px] text-black_900_cc w-[auto]"
                    as="h6"
                    variant="h6"
                  >
                    Modou Fall
                  </Text>
                  <Img
                    src="images/img_trash.svg"
                    className="lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] lg:ml-[371px] xl:ml-[464px] 2xl:ml-[522px] 3xl:ml-[626px] lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    alt="trash"
                  />
                  <Img
                    src="images/img_link.svg"
                    className="lg:h-[19px] xl:h-[24px] 2xl:h-[27px] 3xl:h-[33px] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] lg:w-[18px] xl:w-[23px] 2xl:w-[26px] 3xl:w-[32px]"
                    alt="link"
                  />
                  <Button
                    className="flex items-center justify-center lg:mb-[5px] xl:mb-[6px] 2xl:mb-[7px] 3xl:mb-[9px] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] uexchange_One"
                    size="smIcn"
                    variant="icbFillIndigoA700"
                  >
                    <Img
                      src="images/img_eye.svg"
                      className="flex items-center justify-center lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px]"
                      alt="eye"
                    />
                  </Button>
                </Row>
                <Row className="items-end lg:mt-[20px] xl:mt-[26px] 2xl:mt-[29px] 3xl:mt-[35px] w-[100%]">
                  <Text
                    className="font-semibold lg:mb-[4px] 2xl:mb-[5px] xl:mb-[5px] 3xl:mb-[6px] text-black_900_cc w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    Listes des superviseurs pris en charge
                  </Text>
                  <Text className="font-semibold lg:mb-[3px] 2xl:mb-[4px] xl:mb-[4px] 3xl:mb-[5px] lg:ml-[261px] xl:ml-[327px] 2xl:ml-[367px] 3xl:ml-[441px] 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-indigo_A700 underline w-[auto]">
                    Trier
                  </Text>
                  <Img
                    src="images/img_bookmark.svg"
                    className="ml-[2px] 3xl:mt-[11px] lg:mt-[6px] xl:mt-[8px] 2xl:mt-[9px] car"
                    alt="bookmark"
                  />
                </Row>

                <List
                  className="gap-[0] min-h-[auto] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] w-[100%]"
                  orientation="vertical"
                >
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket One"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Two"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[25px] xl:ml-[31px] 2xl:ml-[35px] 3xl:ml-[42px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Three"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Fann Résidence
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      28 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[22px] xl:ml-[27px] 2xl:ml-[31px] 3xl:ml-[37px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Four"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Five"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[25px] xl:ml-[31px] 2xl:ml-[35px] 3xl:ml-[42px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Six"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Fann Résidence
                    </Text>
                    <Text className="Ilya30mn" variant="body1">
                      28 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[22px] xl:ml-[27px] 2xl:ml-[31px] 3xl:ml-[37px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[2px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Seven"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] mt-[2px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Eight"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Nord-Foire
                    </Text>
                    <Text className="transactionsCounter_One" variant="body1">
                      19 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[25px] xl:ml-[31px] 2xl:ml-[35px] 3xl:ml-[42px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                  <Row className="listticket_one">
                    <Button
                      className="flex items-center justify-center mb-[1px] uexchange_One"
                      variant="icbFillIndigoA70033"
                    >
                      <Img
                        src="images/img_user_44X44.svg"
                        className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                        alt="ticket Nine"
                      />
                    </Button>
                    <Text className="CashPay" variant="body1">
                      Cash Pay
                    </Text>
                    <Text className="sacré-coeurCounter2" variant="body1">
                      Sacré-coeur 3
                    </Text>
                    <Text className="transactionsCounter" variant="body1">
                      42 transactions
                    </Text>
                    <Button
                      className="font-semibold lg:ml-[21px] xl:ml-[27px] 2xl:ml-[30px] 3xl:ml-[36px] mt-[1px] lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[11%]"
                      shape="RoundedBorder8"
                      size="md"
                    >
                      Voir
                    </Button>
                  </Row>
                </List>
              </Column>
            </Column>
          </Column>
          <Column className="items-center w-[15%]">
            <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
              <Button
                className="flex items-center justify-center uexchange_One"
                variant="icbFillGray200"
              >
                <Img
                  src="images/img_settings.svg"
                  className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                  alt="settings"
                />
              </Button>
              <Text
                className="font-semibold text-black_900 w-[auto]"
                variant="body1"
              >
                Boubou
              </Text>
              <Img
                src="images/img_ellipse1.png"
                className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                alt="EllipseOne"
              />
            </Row>
            <List
              className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
              orientation="vertical"
            >
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorTwo"
                      alt="VectorTwo"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorOne"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorThree"
                      alt="VectorThree"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorFour"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
            </List>
            <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
              <Text className="columnfluxdetransac_two" variant="body2">
                Flux de transactions
              </Text>
              <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                  <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                    <Row className="items-start justify-between w-[100%]">
                      <Text className="Thirty" variant="body2">
                        30%
                      </Text>
                      <Text className="Forty" variant="body2">
                        40%
                      </Text>
                    </Row>
                    <Text className="columnthirty" variant="body2">
                      30%
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Button
              className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
              leftIcon={
                <Img
                  src="images/img_camera_24X24.svg"
                  className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                  alt="camera"
                />
              }
              shape="RoundedBorder8"
              size="lg"
            >
              <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                Réapprovisionner
              </div>
            </Button>
          </Column>
        </Row>
      </Column>
    </>
  );
};

export default InfosuperviseurPage;
