import { CalendarTwoTone } from '@ant-design/icons';
import { Button, Space, Table, Input } from 'antd';
import AppelService from '../../services/appel-service';
import moment from 'moment'
import axios from 'axios';
import StatusActions from './StatusActions';
import BankerActions from './BankerActions'
// import { Calendar } from 'react-calendar';
// import DatePicker from "react-datepicker";
import Date from 'components/Calendar';
import ServiceActions from './ServiceActions';

import { SearchOutlined } from '@ant-design/icons';
import React, { useRef, useState, useEffect } from "react";
import Highlighter from 'react-highlight-words';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;


const List1 = ({ state }) => {

  let locale = {
    emptyText: 'Pas appel de fond',
  };


  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);


  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [dataAll, setDataAll] = useState("");



  const [content, setContent] = useState("");
  useEffect(() => {
    setLoggedIn(false)
    AppelService.getAllAppel_().then(
      (response) => {
        setLoggedIn(true)
        setContent(response.data.response.appeldefonds);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);





  const [dates, setDates] = useState(null);
  const [values, setValues] = useState(null);


  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;

    //console.log('value >> '+ dates[0]+' dates >>'+ dates[0])
    return !!tooEarly || !!tooLate;
  };

  function convert(str) {
    var dates = new Date(str),
      mnth = ("0" + (dates.getMonth() + 1)).slice(-2),
      day = ("0" + dates.getDate()).slice(-2);
    return [dates.getFullYear(), mnth, day].join("-");
  }

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }

  };





  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});



  ///
  const handleChange = (pagination, filters, sorter) => {

    setFilteredInfo(filters);
    setSortedInfo(sorter);

  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button

            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#FFC069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  ///


  const columns = [

    {
      title: '',
      dataIndex: 'icone',
      key: 'icone',
      width: '10%',
      render: (text, record) => {
        return <ServiceActions record={record} />
      },


      sortOrder: sortedInfo.columnKey === 'icone' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Nom',
      dataIndex: 'useer',
      key: 'user',
      width: '15%',
      ...getColumnSearchProps('user'),
      render: (text, record) => {
        return record?.user
      },
    },

    {
      title: 'Montant(FCFA)',
      dataIndex: 'montant',
      key: 'montant',
      width: '18%',


      render: (text, record) => {
        return record?.amount
      },
    },


    {
      title: 'Date',
      dataIndex: 'date_heure',
      key: 'date_heure',
      width: '25%',
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
      },

    },



    {
      title: 'Status',
      dataIndex: 'transactions',
      key: 'status',
      width: '20%',

      render: (text, record) => {
        return <StatusActions record={record} />
      },


    },

    {
      title: 'Actions ',
      key: 'action',
      dataIndex: 'banker_approvement',


      render: (banker_approvement, record) => {
        return <BankerActions record={record} />
      },
      ellipsis: true,
    },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >


      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 6 }} dataSource={content} onChange={handleChange} loading={!isLoggedIn} />
    </>
  );
};
export default List1;