import { Space, Modal } from 'antd';
import React, { useState } from 'react';
import { EditTwoTone, DeleteTwoTone, CheckCircleTwoTone, ClockCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { IoInformation } from 'react-icons/io5'
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateReclamation } from "../../actions/update-reclamation";
import UserService from "../../services/user.service";
import OTPInput, { ResendOTP } from "otp-input-react";
import { verifyPhoneOtp } from "../../actions/user";
import { Column, Row, Img, Text, Input, List, Stack, Button } from "components";

// updatereap

export default function BankerActions(props) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const state = props?.record


  ///
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [montant, setMontant] = React.useState("");
  const [otp, setOtp] = React.useState("");
  // const navigate = useNavigate();



  // const [successful, setSuccessful] = useState(false);
  const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id

  const { message } = useSelector(state => state.message);
  // const dispatch = useDispatch();

  const [content, setContent] = useState("");

  ///

  const generate = () => {

    setIsModalOpen(true);

  };




  const userId = currentUser.response.user.id


  const handleSubmit1 = (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);


        dispatch(updateReclamation(state.id, state.montant, state.user, "SUCCESS", state.senderId, state.receiverId, state.id_trx, state.service))
        // window.location.reload();
        navigate("/dashboardsuperviseur")

      })

      .catch(() => {
        setSuccessful(false);

      });

  };

  const handleSubmit0 = (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);


        dispatch(updateReclamation(state.id, state.montant, state.user, "FAILED", state.senderId, state.receiverId, state.id_trx, state.service))
        // window.location.reload();
        navigate("/dashboardsuperviseur")
        // window.location.reload();
      })

      .catch(() => {
        setSuccessful(false);

      });

  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };


  const navigate = useNavigate();


  const [approvement, setApprovement] = useState(props?.record?.banker_approvement?.approvement)
  const [loading, setLoading] = useState(false)
  const [sold, setSold] = useState(props?.record?.deed_of_sale?.sold)
  const [commandeConfirmed, setCommandeConfirmed] = useState(props?.record?.order?.confirmed)
  console.log('confirmed : ', commandeConfirmed)
  const status = props?.record?.status
  if (loading) return <Button type="primary" shape="circle" loading />
  if (status === 'SUCCESS') return null
  if (status === 'FAILED') return null


  return (
    <Space size="small">
      <button onClick={generate}>< CheckCircleTwoTone twoToneColor="#52C41A" title="valider la reclamation" /></button>
      <Modal title="verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel} closable={false} >

        {message && (
          <div className="form-group">
            <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
              {message}
            </div>
          </div>
        )}


        <div class=" p-2 text-center">
          <Img
            src="images/coolupay.png"
            className="lg:h-[70px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[70%]"
            alt="logobbsOne"
          />
          <h4>Please enter the one code OTP</h4>


          <br />
          <div className="absolute content-center items-center  right-[-10] top-[20] ">
            <OTPInput
              inputStyles={{
                width: "20%",
                height: "30px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold"
              }}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false} />
            <div></div>
          </div>
        </div>
        <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
          <Button
            className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"

            shape="RoundedBorder8"

            variant="FillBluegray100"
            onClick={handleOk}
          >
            Annuler
          </Button>
          <Button

            className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
            shape="RoundedBorder8"
            onClick={handleSubmit1}

            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Confirmer
          </Button>
        </Row>
      </Modal>


      <button onClick={showModal1}><CloseCircleTwoTone twoToneColor="#EB2F96" title="Rejeter demande" /></button>
      <Modal title="verification code OTP" footer={null} open={isModalOpen1} onOk={handleOk1} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel1} closable={false}>

        {message && (
          <div className="form-group">
            <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
              {message}
            </div>
          </div>
        )}


        <div class=" p-2 text-center">
          <Img
            src="images/coolupay.png"
            className="lg:h-[70px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[70%]"
            alt="logobbsOne"
          />
          <h4>Please enter the one code OTP</h4>


          <br />
          <div className="absolute content-center items-center  right-[-10] top-[20] ">
            <OTPInput
              inputStyles={{
                width: "20%",
                height: "30px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold"
              }}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false} />
            <div></div>
          </div>
        </div>
        <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
          <Button
            className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"

            shape="RoundedBorder8"

            variant="FillBluegray100"
            onClick={handleOk1}
          >
            Annuler
          </Button>
          <Button

            className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
            shape="RoundedBorder8"
            onClick={handleSubmit0}

            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Confirmer
          </Button>
        </Row>
      </Modal>


    </Space>
  )

}

//onClick={handleSubmit}
