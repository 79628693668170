import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

// get balance 
const getBalance = () => {
  //return axios.get(API_URL + "user/balance",{id});
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "id": userId,
  });


  const config = {
    method: 'post',
    url: API_URL + "/balance",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};

const count = () => {
  //return axios.get(API_URL + "user/balance",{id});
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "id": userId,
  });


  const config = {
    method: 'post',
    url: API_URL + "/countAll",
    // url: "http://3.238.7.217:4041/api/v1/countAll",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};

//get all transactions
const getAllTransaction = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });

  //localhost:4041/api/v1/getAllReclamation
  const config = {
    method: 'post',
    //url: "http://localhost:4041/api/v1/listeTrans",

    url: API_URL + "/listeTrans",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};
//get all reclamation
const getAllReclamation = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "receiverId": userId,
  });

  // url: 'localhost:4041/api/v1/listeTrans',
  const config = {
    method: 'post',
    // localhost:4041/api/v1/createReclamation
    url: API_URL + "/getAllReclamation",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};

const countReclamation = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });

  // url: 'localhost:4041/api/v1/listeTrans',
  const config = {
    method: 'post',
    // localhost:4041/api/v1/createReclamation
    url: API_URL + "/countReclamationr",
    // url:  "http://3.238.7.217:4041/api/v1/countReclamationr",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};


// generer code otp
const generateOtp = () => {

  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id

  const data = JSON.stringify({
    "userId": userId,
  });


  const config = {
    method: 'post',
    url: API_URL + "/generateOtp",
    headers: authHeader(),

    data: data
  };
  return axios(config)
}

//verify code otp
const verifyPhoneOtp = (userId, otp) => {

  var data = JSON.stringify({
    "userId": userId,
    "otp": otp
  });

  var config = {
    method: 'post',
    url: API_URL + '/verifyPhoneOtp',
    headers: authHeader(),
    data: data
  };

  return axios(config)

}


const countCaissier = () => {
  //return axios.get(API_URL + "user/balance",{id});
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });


  const config = {
    method: 'post',
    url: API_URL + '/countCaissier',
    // url: "http://3.238.7.217:4041/api/v1/countCaissier",
    headers: authHeader(),

    data: data
  };
  return axios(config)
};

///update profile
const updateProfile = (userId, firstName, lastName, email, phone) => {
  var data = JSON.stringify({
    "userId": userId,
    "firstName": firstName,
    "lastName": lastName,
    "email": email,
    "phone": phone
  });
  const config = {
    method: 'put',
    url: API_URL + '/updateProfile',
    // url : "http://3.238.7.217:4041/api/v1/updateProfile",
    headers: authHeader(),

    data: data
  };
  return axios(config)

}

const updatePassword = (userId, password, newPassword) => {
  var data = JSON.stringify({
    "userId": userId,
    "password": password,
    "newPassword": newPassword
  });
  const config = {
    method: 'put',
    // url: "http://localhost:4041/api/v1/updatePassword",
    url: API_URL + '/updatePassword',
    headers: authHeader(),

    data: data
  };
  return axios(config)

}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBalance, getAllTransaction, updateProfile, updatePassword,
  generateOtp, verifyPhoneOtp, getAllReclamation, count, countCaissier, countReclamation
};
